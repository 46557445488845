import React from 'react';
import style from './NavAssisted.module.css';
import NavAssistedButton from './NavAssistedButton/NavAssistedButton';
// import IconLng from '../../OtherComponents/IconLng'
import IconMail from '../../OtherComponents/IconMail';
// import NavAssistedButtonLng from './NavAssistedButtonLng/NavAssistedButtonLng';
import { useTranslation } from 'react-i18next'


const NavAssisted = ({ changeLanguage }) => {
    const { t } = useTranslation();
    return (
        <nav className={style.nav}>
            {/* <div className={style.small}>
                <NavAssistedButtonLng changeLanguage={changeLanguage} img={<IconLng />} alt='' title={t("nav7")} />
            </div> */}
            <div className={style.middle}>
                {/* <NavAssistedButton item='Контакты' to='/contact' img={<IconMail />} alt='' title='Контакты' /> */}
                <NavAssistedButton item={t("nav8")} to='/contact' img={<IconMail />} alt='' title={t("nav8")} />
            </div>
        </nav>
    );
}

export default NavAssisted;