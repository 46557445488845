import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        detection: {
            order: [], // Убираем все методы обнаружения языка
            cache: []
        },
        interpolation: {
            escapeValue: false
        }
    });

// Устанавливаем язык по умолчанию на английский
i18n.changeLanguage('en');

i18n.on('languageChanged', (lang) => {
    document.documentElement.lang = lang;
});

export default i18n;
