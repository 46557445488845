import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import Layout from './components/OtherComponents/Layout/Layout';
import { withSuspense } from './hoc/withSuspense'
import { useTranslation } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import './18n';

const ProfileComponent = withSuspense(React.lazy(() => import('./components/Profile/Profile')));
const StaticMixerComponent = withSuspense(React.lazy(() => import('./components/StaticMixer/StaticMixer')));
const FlowChemistryComponent = withSuspense(React.lazy(() => import('./components/FlowChemistry/FlowChemistry')));
const FoamFormationeComponent = withSuspense(React.lazy(() => import('./components/FoamFormatione/FoamFormatione')));
const CustomizedPartsComponent = withSuspense(React.lazy(() => import('./components/CustomizedParts/CustomizedParts')));
const DownloadsComponent = withSuspense(React.lazy(() => import('./components/Downloads/Downloads')));
const PortraitComponent = withSuspense(React.lazy(() => import('./components/Portrait/Portrait')));
const ContactComponent = withSuspense(React.lazy(() => import('./components/Contact/Contact')));
const PrivacyComponent = withSuspense(React.lazy(() => import('./components/Privacy/Privacy')));

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  //
  const [componentsLoaded, setComponentsLoaded] = useState(false);

  // Проверяем, если текущий путь /index.html, перенаправляем на /
  useEffect(() => {
    if (window.location.pathname === '/index.html') {
      navigate('/');
    }
  }, [navigate]);

  // Функция для установки состояния загрузки компонентов
  const handleComponentsLoad = () => {
    // debugger;
    setComponentsLoaded(true);
  };

  const { i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  }

  return (
    <HelmetProvider>
      <div className='app-wrapper'>
        <div className='container'>
          <div className='header'>
            <Header changeLanguage={changeLanguage} />
          </div>
          <div className={location.pathname.includes('/') || location.pathname === '/' ? 'profile-content' : 'content'}>
            <Routes>
              <Route exact path="/" element={<Layout page={<ProfileComponent to="/*" onLoad={handleComponentsLoad} />} />} />
              <Route path="staticmixer/*" element={<Layout page={<StaticMixerComponent onLoad={handleComponentsLoad} />} />} />
              <Route path="mixing/*" element={<Layout page={<FlowChemistryComponent onLoad={handleComponentsLoad} />} />} />
              <Route path="foamformatione/*" element={<Layout page={<FoamFormationeComponent onLoad={handleComponentsLoad} />} />} />
              <Route path="customizedparts/*" element={<Layout page={<CustomizedPartsComponent onLoad={handleComponentsLoad} />} />} />
              <Route path="downloads/*" element={<Layout page={<DownloadsComponent onLoad={handleComponentsLoad} />} />} />
              <Route path="portrait/*" element={<Layout page={<PortraitComponent onLoad={handleComponentsLoad} />} />} />
              <Route path="contact/*" element={<Layout page={<ContactComponent onLoad={handleComponentsLoad} />} />} />
              {/* <Route path="privacy/*" element={<Layout page={<PrivacyComponent onLoad={handleComponentsLoad} />} />} /> */}
              <Route path="*" element={<Navigate to={"/"} />} />
            </Routes>
          </div>
          <div className='large' style={{ minHeight: '280px' }} />
          <div className='footer'>
            <Suspense fallback={<div></div>}>
              {componentsLoaded && <Footer />}
            </Suspense>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}


export default App;
