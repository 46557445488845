import React, { useState } from 'react';
import style from './DropDownNav.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import BtnNav from '../BtnNav';
import { useTranslation } from 'react-i18next';
import BtnLng from '../BtnLng/BtnLng';

const DropDownNav = (props) => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleLinkClick = () => {

        setIsMenuOpen(false);
    };

    return (
        <div className={style.dropdown}>
            <button className={style.dropbtn} onClick={toggleMenu}>
                <BtnNav />
                <form className={(props.to === useLocation().pathname) ? style.btnBorderActive : style.btnBorder} />
            </button>
            {isMenuOpen && (
                <div className={style.dropdown_content}>
                    <NavLink to='/' onClick={handleLinkClick}
                        className={({ isActive }) => isActive ? style.activeLink : ''}>
                        {t("nav1")}
                    </NavLink>
                    <NavLink to='/staticmixer' onClick={handleLinkClick}
                        className={({ isActive }) => isActive ? style.activeLink : ''}>
                        {t("nav2")}
                    </NavLink>
                    <NavLink to='/mixing' onClick={handleLinkClick}
                        className={({ isActive }) => isActive ? style.activeLink : ''}>
                        {t("nav3")}
                    </NavLink>
                    <NavLink to='/foamformatione' onClick={handleLinkClick}
                        className={({ isActive }) => isActive ? style.activeLink : ''}>
                        {t("nav4")}
                    </NavLink>
                    <NavLink to='/customizedparts' onClick={handleLinkClick}
                        className={({ isActive }) => isActive ? style.activeLink : ''}>
                        {t("nav5")}
                    </NavLink>
                    <NavLink to='/downloads' onClick={handleLinkClick}
                        className={({ isActive }) => isActive ? style.activeLink : ''}>
                        {t("nav6")}
                    </NavLink>
                    <NavLink to='/portrait' onClick={handleLinkClick}
                        className={({ isActive }) => isActive ? style.activeLink : ''}>
                        {t("nav7")}
                    </NavLink>
                    <div className={style.middle}>
                        <NavLink to='/contact' onClick={handleLinkClick}
                            className={({ isActive }) => isActive ? style.activeLink : ''}>
                            {t("nav8")}
                        </NavLink>
                    </div>
                    {/* <div className={style.small}>
                        <BtnLng changeLanguage={props.changeLanguage} />
                    </div> */}
                </div>
            )
            }
        </div >

    );
}

export default DropDownNav;