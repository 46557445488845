import React from 'react';
import style from './FooterBox.module.css';
import { useTranslation } from 'react-i18next';

const FooterBox = (props) => {
    const { t } = useTranslation();
    return (
        <ul className={style.content}>
            <li className={style.main}>
                {props.main}
            </li>
            <li className={style.contry}>
                {props.contry}
            </li>
            <li className={style.city}>
                {props.city}
            </li>
            <li className={style.street}>
                {props.street}
            </li>
            <li className={style.index}>
                {props.index}
            </li>
            <li className={style.phone}>
                <a className={style.phone} href={`tel:${props.phone}`}>{t("PersonContact.phone")}{props.phone}</a>
            </li>
            <li className={style.email}>
                <a className={style.email} href={`mailto:${props.email}`}>{t("PersonContact.email")}{props.email}</a>
            </li>
        </ul>
    );
}

export default FooterBox;