import React from 'react';
import style from './Navbar.module.css';
import NavButton from './NavButton/NavButton';
import { useTranslation } from 'react-i18next';


const Navbar = () => {
    const { t } = useTranslation();
    // const { t, i18n } = useTranslation();
    // const changeLanguage = (language) => {
    //     i18n.changeLanguage(language);
    // }
    return (
        <nav className={style.nav}>

            {/* <NavButton item={'Главная'} to='/' title='Главная' /> */}
            <NavButton item={t("nav1")} to='/' title={t("nav1")} />
            <NavButton item={t("nav2")} to='/staticmixer' title={t("nav2")} />
            <NavButton item={t("nav3")} to='/mixing' title={t("nav3")} />
            <NavButton item={t("nav4")} to='/foamformatione' title={t("nav4")} />
            <NavButton item={t("nav5")} to='/customizedparts' title={t("nav5")} />
            <NavButton item={t("nav6")} to='/downloads' title={t("nav6")} />
            <NavButton item={t("nav7")} to='/portrait' title={t("nav7")} />
        </nav>
    );
}

export default Navbar;