import React, { Suspense } from "react";

export const withSuspense = (Component) => {
    return (props) => {
        return (
            <Suspense>
                <Component {...props} />
            </Suspense>
        );
    };
};