import React from 'react';
import style from './Header.module.css';
import Navbar from './Navbar/Navbar';
import NavAssisted from './NavAssisted/NavAssisted';
import DropDownNav from '../OtherComponents/DropDownNav/DropDownNav';
import { NavLink } from 'react-router-dom';


const Header = ({ changeLanguage }) => {
    return (
        <header className={style.header}>
            <NavLink to='/'><img src="logo.svg" alt='' /></NavLink>
            {/* <NavLink to='/'><img src="logo_b_m.webp" alt='' /></NavLink> */}
            <Navbar className={style.navbar} />
            <NavAssisted className={style.navAssisted} changeLanguage={changeLanguage} />
            <DropDownNav className={style.navDropDownNav} changeLanguage={changeLanguage} />
            {/* <div >&nbsp;РУС</div> */}
        </header>
    );
}

export default Header;