import React from 'react';
import style from './Footer.module.css';
import FooterBox from '../OtherComponents/FooterBox/FooterBox';
import FooterBoxNav from '../OtherComponents/FooterBoxNav/FooterBoxNav';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className={style.footer}>
            <div className={style.box}>
                {/* <img src="logo_w.png" alt='' /> */}
                <img src="logo_w_m.webp" alt='' />
                <div className={style.item}>
                    <FooterBox
                        main={t("Contact.firstPlace.main")}
                        contry={t("Contact.firstPlace.country")}
                        city={t("Contact.firstPlace.city")}
                        street={t("Contact.firstPlace.street")}
                        index={t("Contact.firstPlace.index")}
                        phone={t("Contact.firstPlace.phone")}
                        email={t("Contact.firstPlace.email")}
                    />
                </div>
                <div className={style.item}>
                    <FooterBox
                        main={t("Contact.secondPlace.main")}
                        contry={t("Contact.secondPlace.country")}
                        city={t("Contact.secondPlace.city")}
                        street={t("Contact.secondPlace.street")}
                        index={t("Contact.secondPlace.index")}
                        phone={t("Contact.secondPlace.phone")}
                        email={t("Contact.secondPlace.email")}
                    />
                </div>
                <div className={style.item}>
                    <FooterBoxNav
                        main={t("profile.textHead")}
                        herf1={t("nav2")}
                        toHerf1='/staticmixer'
                        herf2={t("nav3")}
                        toHerf2='/flowchemistry'
                        herf3={t("nav4")}
                        toHerf3='/foamformatione'
                        herf4={t("nav5")}
                        toHerf4='/customizedparts'
                    />
                </div>
                {/* <div className={style.item}>
                    <FooterBox
                        main={t("Contact.thirdPlace.main")}
                        contry={t("Contact.thirdPlace.country")}
                        city={t("Contact.thirdPlace.city")}
                        street={t("Contact.thirdPlace.street")}
                        index={t("Contact.thirdPlace.index")}
                        phone={t("Contact.thirdPlace.phone")}
                        email={t("Contact.thirdPlace.email")}
                    />
                </div> */}
                {/* <div className={style.large}>
                    <div className={style.item}>
                        <FooterBoxNav
                            main={t("profile.textHead")}
                            herf1={t("nav2")}
                            toHerf1='/staticmixer'
                            herf2={t("nav3")}
                            toHerf2='/flowchemistry'
                            herf3={t("nav4")}
                            toHerf3='/foamformatione'
                            herf4={t("nav5")}
                            toHerf4='/customizedparts'
                        />
                    </div>
                </div> */}
            </div>
            <div className={style.disclaimer}>Copyright © 2024 IVF&VA EXTRUSION ENGINEERING</div>
        </footer>
    );
}

export default Footer;