import React from 'react';
import style from './FooterBoxNav.module.css';
import { NavLink } from 'react-router-dom';

const FooterBoxNav = (props) => {
    return (
        <ul className={style.content}>
            <li className={style.main}>
                {props.main}
            </li>
            <li className={style.herf}>
                <NavLink to={props.toHerf1}>
                    {props.herf1}
                </NavLink>
            </li>
            <li className={style.herf}>
                <NavLink to={props.toHerf2}>
                    {props.herf2}
                </NavLink>
            </li>
            <li className={style.herf}>
                <NavLink to={props.toHerf3}>
                    {props.herf3}
                </NavLink>
            </li>
            <li className={style.herf}>
                <NavLink to={props.toHerf4}>
                    {props.herf4}
                </NavLink>
            </li>
        </ul>
    );
}

export default FooterBoxNav;