import React from 'react';

const BtnNav = () => {
    return (
        <svg height="18" width="17" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-1324 -31)">
                <path d="m0 0h17v4h-17z" transform="translate(1324 31)" />
                <path d="m0 0h17v4h-17z" transform="translate(1324 38)" />
                <path d="m0 0h17v4h-17z" transform="translate(1324 45)" />
            </g>
        </svg>
    );
}

export default BtnNav;