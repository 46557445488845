import React from 'react';
import style from './NavButton.module.css';
import { NavLink, useLocation } from 'react-router-dom';

const NavButton = (props) => {

    return (
        <div className={style.item}>
            <NavLink className={({ isActive }) => isActive ? style.activeLink : ''} to={props.to} >
                {props.item}
            </NavLink>
            <form className={(props.to === useLocation().pathname) ? style.itemBorderActive : style.itemBorder} />
        </div>
    );
}

export default NavButton;