import React from 'react';
import style from './NavAssistedButton.module.css';
import { NavLink, useLocation } from 'react-router-dom';

const NavAssistedButton = (props) => {
    return (
        <div className={style.item}>
            <NavLink className={({ isActive }) => isActive ? style.activeLink : ''} to={props.to}>
                <form className={style.text}>{props.item}</form>
                {props.img}
            </NavLink>
            <form className={(props.to === useLocation().pathname) ? style.itemBorderActive : style.itemBorder} />
        </div>
    );
}

export default NavAssistedButton;