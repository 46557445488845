import React from 'react';
import style from './Layout.module.css';

const Layout = (props) => {
    window.scrollTo(0, 0);
    return (
        <div className={style.content} >
            <div>
                {props.page}
            </div>
        </div>
    );
}

export default Layout;